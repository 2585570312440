exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-aneks-potwierdzenie-js": () => import("./../../../src/pages/aneks-potwierdzenie.js" /* webpackChunkName: "component---src-pages-aneks-potwierdzenie-js" */),
  "component---src-pages-do-pobrania-js": () => import("./../../../src/pages/do-pobrania.js" /* webpackChunkName: "component---src-pages-do-pobrania-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-logowanie-js": () => import("./../../../src/pages/logowanie.js" /* webpackChunkName: "component---src-pages-logowanie-js" */),
  "component---src-pages-o-trust-js": () => import("./../../../src/pages/o-trust.js" /* webpackChunkName: "component---src-pages-o-trust-js" */),
  "component---src-pages-panel-klienta-js": () => import("./../../../src/pages/panel-klienta.js" /* webpackChunkName: "component---src-pages-panel-klienta-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-portfolio-deweloperskie-js": () => import("./../../../src/pages/portfolio-deweloperskie.js" /* webpackChunkName: "component---src-pages-portfolio-deweloperskie-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-rejestracja-js": () => import("./../../../src/pages/rejestracja.js" /* webpackChunkName: "component---src-pages-rejestracja-js" */),
  "component---src-pages-resetowanie-hasla-js": () => import("./../../../src/pages/resetowanie-hasla.js" /* webpackChunkName: "component---src-pages-resetowanie-hasla-js" */),
  "component---src-pages-ryzyka-js": () => import("./../../../src/pages/ryzyka.js" /* webpackChunkName: "component---src-pages-ryzyka-js" */),
  "component---src-pages-zamowienie-js": () => import("./../../../src/pages/zamowienie.js" /* webpackChunkName: "component---src-pages-zamowienie-js" */),
  "component---src-pages-zamowienie-niepowodzenie-js": () => import("./../../../src/pages/zamowienie-niepowodzenie.js" /* webpackChunkName: "component---src-pages-zamowienie-niepowodzenie-js" */),
  "component---src-pages-zamowienie-potwierdzenie-js": () => import("./../../../src/pages/zamowienie-potwierdzenie.js" /* webpackChunkName: "component---src-pages-zamowienie-potwierdzenie-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

